$.fn.lib_flickity = function () {
    let selector = $(this);
    if (!html.hasClass("ie9")) {
        $.importScript(cdnjs.flickity, function() {
            cssLoaded(function () {
                selector.each(function(){
                    let elm = $(this);
                    let options = elm.data("lib-flickity");
                    let items = elm.children().length;
                    let nav = elm.parent().find("[data-lib-flickity-nav]");


                    if(items < ((typeof options["desktopCount"] !== "undefined") ? options["desktopCount"] : 2)) {
                        if(nav.length) {
                            nav.remove();
                        }
                        return;
                    }

                    elm.on("ready.flickity", function(e){
                        elm.find(".flickity-button").lui_ripple();
                        if (elm.find(".flickity-button[disabled]").length === 2) {
                            elm.addClass("flickity-nav-disabled");
                        }
                    });

                    let slider = $(this).flickity({
                        cellAlign: (typeof options["cellAlign"] !== "undefined") ? options["cellAlign"] : "left",
                        setGallerySize: true,
                        autoPlay: options["autoplay"],
                        pageDots: true,
                        wrapAround: (typeof options["wrapAround"] !== "undefined") ? options["wrapAround"] : true,
                        contain: true,
                        adaptiveHeight: false,
                        pauseAutoPlayOnHover: false,
                        prevNextButtons: true,
                        percentPosition: false,
                        watchCSS: options["watchCSS"]
                    });

                    let flkty = slider.data('flickity');

                    if (typeof options["flex"] !== "undefined") {
                        elm.addClass("flickity-flex");

                        win.on("resize",debounce(function () {
                            elm.removeClass("flickity-flex");
                            elm.flickity("resize");
                            elm.addClass("flickity-flex");
                        },250));
                    }

                    if (typeof options["parallax"] !== "undefined") {
                        let carousel_img = slider.find('.part_ui_image'),
                            docStyle = document.documentElement.style,
                            transformProp = typeof docStyle.transform === 'string' ? 'transform' : 'WebkitTransform';

                        slider.on('scroll.flickity', function () {
                            flkty.slides.forEach(function (slide, i) {
                                let img = carousel_img[i],
                                    x = (slide.target + flkty.x) * -1;

                                img.style[transformProp] = 'translateX(' + Math.round(x) + 'px)';
                            });
                        });
                    }

                    if (nav.length) {
                        nav.on("click", function(){
                            let direction = $(this).data("lib-flickity-nav");
                            if (direction === "prev") {
                                slider.flickity('previous');
                            } else {
                                slider.flickity('next');
                            }
                        });

                        slider.on('change.flickity',function (event, index) {
                            nav.removeAttr("disabled");
                            if(index === flkty.slides.length - 1) {
                                nav.each(function () {
                                    if($(this).is("[data-lib-flickity-nav=\"next\"]")) {
                                        $(this).attr("disabled","disabled");
                                    }
                                })
                            }
                            if(index === 0) {
                                nav.each(function () {
                                    if($(this).is("[data-lib-flickity-nav=\"prev\"]")) {
                                        $(this).attr("disabled","disabled");
                                    }
                                })
                            }
                        });
                    }
                });
            });
        });
    }
};
